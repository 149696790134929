const LEARNING_JOURNEY_BASE_PATH = '/learning-journey';

const PATHS = {
  LEARNING_JOURNEY: {
    LIST: `${LEARNING_JOURNEY_BASE_PATH}/v1`,
    DETAIL: ({ id }: { id: string }) => `${LEARNING_JOURNEY_BASE_PATH}/v1/${id}`,
    COURSES: ({ id }: { id: string }) => `${LEARNING_JOURNEY_BASE_PATH}/v1/${id}`,
    META_DATA: ({ journeyId, courseId }: { journeyId: string; courseId: string }) =>
      `${LEARNING_JOURNEY_BASE_PATH}/v1/${journeyId}/courses/${courseId}`,

    GET_TEAMS: `${LEARNING_JOURNEY_BASE_PATH}/v1/learning-journeys/teams`,
    GET_USERS: `${LEARNING_JOURNEY_BASE_PATH}/v1/learning-journeys/users`,
    ASSIGN_TEAM: `${LEARNING_JOURNEY_BASE_PATH}/v1/assign/teams`,
    ASSIGN_USERS: `${LEARNING_JOURNEY_BASE_PATH}/v1/assign/users`,
    ASSIGN_SELF: `${LEARNING_JOURNEY_BASE_PATH}/v1/assign/self`,
  },
};

export { PATHS };
