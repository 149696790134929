import { useAppSelector } from '../../../hooks';
import type { RootState } from '../../../store';

// types

type CMS = {
  learningJourney: LearningJourney;
};

type LearningJourney = {
  data: {
    attributes: Attributes;
  };
};

type Attributes = {
  ListAllLJs: ListAllLearningJourneys;
  LearningJourneyCard: LearningJourneyCard;
  LearningJourneyDetail: LearningJourneyDetail;
  LearningJourneyModal: LearningJourneyModal;
  ToastMessages: ToastMessages;
};

type LearningJourneyStatus = {
  inProgressLabel: string;
  overDueLabel: string;
  completedLabel: string;
  assignedLabel: string;
  statusPlaceHolder: string;
};

type LearningJourneyCard = {
  thumbnailAlt: string;
  coursesCountLabel: string;
  linkAriaLabel: string;
  inProgressBadgeLabel: string;
  notStartedBadgeLabel: string;
  completedBadgeLabel: string;
};

type ListAllLearningJourneys = {
  pageHeading: string;
  pageDescription: string;
  allLearningJourneysFetched: string;
  loadingMoreLearningJourneys: string;
  errorLoadingLearningJourneys: string;
  noJourneysActionLabel: string;
  noJourneysMessage: string;
  LJStatus: LearningJourneyStatus;
  LJNavigator: LearningJourneyNavigator;
  viewAllLJsButton: string;
};

type LearningJourneyNavigator = {
  allLJs: string;
};

type LearningJourneyDetail = {
  headerBackLabel: string;
  allCoursesFetched: string;
  loadingMoreCourses: string;
  errorLoadingCourses: string;
  detailsLoadingErrorMessage: string;
  noCoursesForJourneyMessage: string;
  checkoutOtherJourneysMessage: string;
  learningJourneyAssignmentLabel: string;
};

type LearningJourneyModal = {
  title: string;
  teamLabel: string;
  teamPlaceHolder: string;
  memberLabel: string;
  memberPlaceHolder: string;
  dueDateLabel: string;
  dueDatePlaceHolder: string;
  fixDueDateMessage: string;
  cancelButton: string;
  assignLearningJourneyButton: string;
  orLabel: string;
};
type ToastMessages = {
  learningJourneySuccessTitle: string;
  learningJourneyFailureTitle: string;
  learningJourneyUserAssignedSuccessMessage: string;
  learningJourneyTeamAssignedSuccessMessage: string;
  learningJourneyUserAssignedFailedMessage: string;
  learningJourneyTeamAssignedFailedMessage: string;
};
// types

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

const useStrapiLearningJourneyData = () => {
  const data = useAppSelector(strapiDataSelector) as CMS;
  const learningJourneyData = data.learningJourney.data.attributes;
  return learningJourneyData;
};

export default useStrapiLearningJourneyData;
