import { FadeLoader } from 'react-spinners';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import useStrapiLearningJourneyData from '../../../hooks/use-strapi-learning-journey-data';
import { SideBarLayoutPageContentWrapper } from '../../../../../components/Wrapper';
import LearningJourneyCard from './LearningJourneysList/LearningJourneyCard';
import { Button } from '../../../../../components/Buttons';
import cn from '../../../../../utils/cn';
import { LearningJourney } from '../../../types/api';

type LJViewProps = {
  className?: string;
  currentTab?: any;
  currentTabState?: string;
  tab?: any;
  navigateToAllLJs?: any;
};

const LearningJourneyView = ({
  className,
  tab,
  currentTabState,
  navigateToAllLJs,
}: LJViewProps) => {
  console.log(tab);
  console.log(currentTabState);

  //   const cardObserver: any = useRef();
  //   const cardObserverCallBack = useCallback(
  //     (card: any) => {
  //       if (tab && tab.isLoading) return;
  //       if (cardObserver.current) cardObserver.current.disconnect();
  //       cardObserver.current = new IntersectionObserver((cardElement) => {
  //         if (cardElement[0].isIntersecting) {
  //           tab.fetchNextPage();
  //         }
  //       });
  //       if (card) cardObserver.current.observe(card);
  //     },
  //     [tab.isFetchingNextPage, tab.hasNextPage, tab.fetchNextPage]
  //   );
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { ListAllLJs } = useStrapiLearningJourneyData();
  const { LJNavigator } = ListAllLJs;
  return (
    <>
      <SideBarLayoutPageContentWrapper
        className={`h-full flex-col content-around gap-5 py-6 ${className}`}
      >
        {!tab.isLoading && tab.total && tab.data.data.length !== 0 && (
          <ul
            className={cn(
              'grid grid-cols-[repeat(auto-fill,minmax(var(--card-width),1fr))] items-stretch gap-3.5 [--card-width:calc(var(--min-thumbnail-width)+2*var(--card-padding-x))] lg:gap-4',
              '[--min-thumbnail-width:180px] xxs:[--min-thumbnail-width:200px] md:[--min-thumbnail-width:240px] lg:[--min-thumbnail-width:256px] xl:[--min-thumbnail-width:316px]',
              '[--card-padding-y:1.375rem] sm:[--card-padding-y:1.5rem] md:[--card-padding-y:1.875rem]',
              '[--card-padding-x:1.375rem] sm:[--card-padding-x:1.5rem] md:[--card-padding-x:1.875rem]',
              '[--min-thumbnail-height:calc(var(--min-thumbnail-width)/var(--lj-card-thumbnail-aspect-ratio))]',
              '[--lj-card-thumbnail-aspect-ratio:800/300]',
              className
            )}
          >
            {tab.data.data.map((learningJourney: LearningJourney) => {
              return (
                <li key={learningJourney.id} className="flex w-full items-stretch">
                  <LearningJourneyCard learningJourney={learningJourney} />
                </li>
              );
            })}
          </ul>
        )}
      </SideBarLayoutPageContentWrapper>
      {!tab.isLoading && tab.data.total === 0 && (
        <div className="h-1/2">
          <div className="flex h-full items-center justify-center text-zinc-200">
            <div className="flex flex-col">
              <h2 className="m-auto text-base font-semibold">
                {ListAllLJs.noJourneysMessage} {currentTabState}
              </h2>

              <Button
                className="m-auto my-4 w-fit bg-base-brand py-2 text-sm font-semibold"
                onClick={currentTabState != LJNavigator.allLJs && navigateToAllLJs}
              >
                {currentTabState != LJNavigator.allLJs && ListAllLJs.viewAllLJsButton}
              </Button>
            </div>
          </div>
        </div>
      )}
      {tab.isLoading && (
        <div className="flex h-full items-center justify-center">
          {<FadeLoader color="#E4E4E7" width="3px" />}
        </div>
      )}
      {tab.isFetchingNextPage && (
        <div className="flex justify-center text-zinc-200">
          <p>{ListAllLJs.loadingMoreLearningJourneys}</p>
        </div>
      )}
      {tab.error && (
        <div className="flex h-full items-center justify-center text-zinc-200">
          <p>{strapiErrorMessage['SYSTEM_ERROR']}</p>
        </div>
      )}
    </>
  );
};

export default LearningJourneyView;
