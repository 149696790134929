// TODO: Revisit ?
export const DEFAULT_PAGE_SIZE = {
  JOURNEYS: {
    LIST: {
      ALL: 24,
      COURSES: 40,
    },
  },
};

export const LEARNING_JOURNEY_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
  COMPLETED: 'COMPLETED',
  NO_COURSES: 'NO_COURSES',
  OVER_DUE: 'OVERDUE',
  ALL: 'ALL',
  ASSIGNED: 'ASSIGNED',
};

export const LEARNING_JOURNEY_ERROR_CODE = {
  LEARNING_JOURNEY_NOT_FOUND: 'LEARNING_JOURNEY_NOT_FOUND',
  COURSE_NOT_PART_OF_LEARNING_JOURNEY: 'COURSE_NOT_PART_OF_LEARNING_JOURNEY',
};
