import Dialog from '../../../../../../components/Dialog';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import AssignCourseForm from './AssignCourseForm';

type AssignCourseModalProps = {
  courseId: number;
  modalState: any;
  // refetchDetails?: () => void;
};
const AssignCourseModal = ({ courseId, modalState }: AssignCourseModalProps) => {
  const { assignCourseModal } = useStrapiCoursesData();
  return (
    <div className="mx-3 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {assignCourseModal.title}
      </Dialog.Title>
      <AssignCourseForm
        courseId={courseId}
        modalState={modalState}
        // refetchDetails={refetchDetails}
      />
    </div>
  );
};

export default AssignCourseModal;
