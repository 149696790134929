import { ArrowLeft, Plus } from 'phosphor-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorDisplay } from '../../../../components/DataTables';
import Dialog from '../../../../components/Dialog';
import { BasicSpinner } from '../../../../components/Spinners';
import { USHG_HQ_APP_ROLE } from '../../../../constants';
import cn from '../../../../utils/cn';
import HasAccess from '../../../shared/components/HasAccess';
import useStrapiLearningJourneyData from '../../hooks/use-strapi-learning-journey-data';
import LearningJourneyAssignmentModal from '../LearningJourneyAssignmentPage/LearningJourneyAssignmentModal';

type Props = {
  className?: string;
  query?: any;
  id: number;
};

const LearningJourneyDetailHeading = (props: Props) => {
  const { className, query, id } = props;
  const [assignLjModalOpen, setassignLjModalOpen] = useState(false);

  // cms
  const { LearningJourneyDetail: cms } = useStrapiLearningJourneyData();

  return (
    <header
      className={cn(
        'sticky top-0 z-[1] flex flex-col items-start gap-2 border-b border-zinc-800 bg-zinc-900  px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9',
        className
      )}
    >
      <div className="flex w-[100%] flex-row flex-wrap justify-between">
        {/* Left Section */}
        <Link to="/learning-journeys" className="flex items-center gap-1.5 text-zinc-400">
          <ArrowLeft color="currentColor" className="text-sm font-normal leading-5" />
          <span className="text-sm">{cms.headerBackLabel}</span>
        </Link>
        {/* Right Section */}
        <div className="my-3 flex h-max flex-row items-center gap-2.5 text-xs md:my-0">
          <HasAccess
            roles={[
              USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
              USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
              USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
            ]}
            Fallback={null}
          >
            <Dialog
              open={assignLjModalOpen}
              containerClassName="!max-w-3xl"
              triggerClassName=" h-max !w-fit flex flex-row gap-1 xxs:gap-2 items-center rounded bg-zinc-800 py-3 px-4 md:px-4"
              onOpenChange={(open) => setassignLjModalOpen(open)}
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
              Content={<LearningJourneyAssignmentModal id={id} modalState={assignLjModalOpen} />}
            >
              <Plus size="16px" color="white" />
              <span className="text-xs font-semibold text-zinc-100 md:whitespace-nowrap">
                {cms.learningJourneyAssignmentLabel}
              </span>
            </Dialog>
          </HasAccess>
        </div>
      </div>

      {/* loader */}
      {query.isLoading ? (
        <div className="flex min-h-[64px] items-center py-2 text-zinc-100">
          <BasicSpinner className="!m-0" color="currentColor" />
        </div>
      ) : null}
      {/* has data */}
      {query.data ? (
        <div className="flex flex-col justify-start gap-1">
          <h1 className="header-title font-semibold">{query.data.name}</h1>
          <p className="text-xs font-normal leading-4 text-zinc-400 xs:text-sm xs:leading-5">
            {query.data.description}
          </p>
        </div>
      ) : null}
      {/* error */}
      {query.isError && !query.data ? (
        <ErrorDisplay
          refetch={query.refetch}
          message={cms.detailsLoadingErrorMessage}
          allowsRefetch={true}
          isRefetching={query.isRefetching}
          className="items-start gap-3 py-2 pt-4"
        ></ErrorDisplay>
      ) : null}
    </header>
  );
};

export default LearningJourneyDetailHeading;
