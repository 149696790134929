import Dialog from '../../../../components/Dialog';
import useStrapiLearningJourneyData from '../../hooks/use-strapi-learning-journey-data';
import LearningJourneyAssignmentForm from './LearningJourneyAssignmentForm';

type LearningJourneyjModalProps = {
  id: number;
  modalState: any;
  // refetchDetails?: () => void;
};
const LearningJourneyAssignmentModal = ({ id, modalState }: LearningJourneyjModalProps) => {
  const { LearningJourneyModal } = useStrapiLearningJourneyData();
  return (
    <div className="mx-3 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {LearningJourneyModal.title}
      </Dialog.Title>
      <LearningJourneyAssignmentForm
        id={id}
        modalState={modalState}
        // refetchDetails={refetchDetails}
      />
    </div>
  );
};
export default LearningJourneyAssignmentModal;
