import { useEffect, useState } from 'react';
import useListAllLearningJourneys from '../../../../hooks/use-list-all-learning-journeys';
import useStrapiLearningJourneyData from '../../../../hooks/use-strapi-learning-journey-data';

import { useLocation, useSearchParams } from 'react-router-dom';
import TabWidgets from '../TabWidgets';
import useStatusCount from '../../../../../courses/hooks/useStatusCount';
import LearningJourneyView from '../LearningJourneyView';
import { TabWidgetType } from '../../../../../courses/types/Filter.Helper';
import { STATES } from '../../../../../../constants/user';

// interface Props {
//   className?: string;
// }

const LearningJourneysList = () => {
  const [currentTab, setCurrentTab] = useState<TabWidgetType>();
  const [searchParam, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [onMount, setOnMount] = useState<boolean>(true);
  // const { className } = props;

  // strapi
  const { ListAllLJs } = useStrapiLearningJourneyData();
  const { LJStatus: status, LJNavigator: navigator } = ListAllLJs;

  // tanstack query
  const progressFetch = useListAllLearningJourneys({
    tab: STATES.PROGRESS,
  });

  const overdueFetch = useListAllLearningJourneys({
    tab: STATES.OVERDUE,
  });

  const assignedFetch = useListAllLearningJourneys({
    tab: STATES.ASSIGNED,
  });

  const completedFetch = useListAllLearningJourneys({
    tab: STATES.COMPLETED,
  });

  const allCoursesFetch = useListAllLearningJourneys({
    tab: STATES.ALL,
  });
  useEffect(() => {
    if (
      onMount &&
      location.state?.auto &&
      (searchParam.get('view') === STATES.PROGRESS || searchParam.get('view') === STATES.ASSIGNED)
    ) {
      if (progressFetch?.data?.total === 0) setSearchParams({ view: STATES.ASSIGNED });
      setOnMount(false);
    }
  }, [progressFetch.data, assignedFetch.data, searchParam.get('view'), location.state]);

  const navigateToAllLJs = () => setSearchParams({ view: STATES.ALL });

  const widgets = useStatusCount({
    progressCount: progressFetch.data.total,
    overdueCount: overdueFetch.data.total,
    assignedCount: assignedFetch.data.total,
    completedCount: completedFetch.data.total,
    allCount: allCoursesFetch.data.total,
    statusType: 'LEARNING_JOURNEY',
  });

  return (
    <div className="flex w-full flex-col gap-5">
      <TabWidgets
        selectedWidget={currentTab}
        setSelectedWidget={setCurrentTab}
        widgetData={widgets}
      />
      <main id="main-content">
        {currentTab?.value}
        {currentTab?.value === STATES.PROGRESS && (
          <LearningJourneyView
            tab={progressFetch}
            currentTabState={status.inProgressLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.OVERDUE && (
          <LearningJourneyView
            tab={overdueFetch}
            currentTabState={status.overDueLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.ASSIGNED && (
          <LearningJourneyView
            tab={assignedFetch}
            currentTabState={status.assignedLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.COMPLETED && (
          <LearningJourneyView
            tab={completedFetch}
            currentTabState={status.completedLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.ALL && (
          <LearningJourneyView
            tab={allCoursesFetch}
            currentTabState={navigator.allLJs}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
      </main>
    </div>
  );

  // derived state
  // const hasData = query.data && query.data.pages.length > 0;
  // const learningJourneys = useMemo(() => {
  //   if (!hasData) {
  //     return [];
  //   }

  //   return query.data.pages.map((page) => [...page.data]).flat();
  // }, [query.data, hasData]);

  // if (hasData && learningJourneys.length === 0) {
  //   return (
  //     <EmptyState
  //       action={{
  //         message: cms.noJourneysActionLabel,
  //         path: '/courses?view=all',
  //       }}
  //       message={cms.noJourneysMessage}
  //     />
  //   );
  // }

  // return (
  <></>;
  // <InfiniteScrollV1
  //   className="relative items-stretch p-4 xs:p-5 sm:p-6 md:p-7 lg:px-9"
  //   fetchNextPage={query.fetchNextPage}
  //   isError={query.isError}
  //   isFetchingNextPage={query.isFetchingNextPage}
  //   isInitialLoading={query.isLoading}
  //   hasNextPage={query.hasNextPage ?? false}
  //   allItemsFetchedMessage={cms.allLearningJourneysFetched}
  //   loadingMoreItemsMessage={cms.loadingMoreLearningJourneys}
  //   errorMessage={cms.errorLoadingLearningJourneys}
  //   refetch={query.refetch}
  //   isRefetching={query.isRefetching}
  //   hasData={hasData}
  //   allowsRefetch={true}
  //   isMoreThanOnePage={query.data?.pages && query.data.pages.length > 1}
  //   rootMargin="240px"
  //   showReachedEndMessage={false}
  // >
  //   <ul
  //     className={cn(
  //       'grid grid-cols-[repeat(auto-fill,minmax(var(--card-width),1fr))] items-stretch gap-3.5 [--card-width:calc(var(--min-thumbnail-width)+2*var(--card-padding-x))] lg:gap-4',
  //       '[--min-thumbnail-width:180px] xxs:[--min-thumbnail-width:200px] md:[--min-thumbnail-width:240px] lg:[--min-thumbnail-width:256px] xl:[--min-thumbnail-width:316px]',
  //       '[--card-padding-y:1.375rem] sm:[--card-padding-y:1.5rem] md:[--card-padding-y:1.875rem]',
  //       '[--card-padding-x:1.375rem] sm:[--card-padding-x:1.5rem] md:[--card-padding-x:1.875rem]',
  //       '[--min-thumbnail-height:calc(var(--min-thumbnail-width)/var(--lj-card-thumbnail-aspect-ratio))]',
  //       '[--lj-card-thumbnail-aspect-ratio:800/300]',
  //       className
  //     )}
  //   >
  //     {learningJourneys.map((learningJourney) => {
  //       return (
  //         <li key={learningJourney.id} className="flex w-full items-stretch">
  //           <LearningJourneyCard learningJourney={learningJourney} />
  //         </li>
  //       );
  //     })}
  //   </ul>
  // </InfiniteScrollV1>
  // );
};

export default LearningJourneysList;
