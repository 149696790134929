import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useReduxAuthState } from '../../../hooks';
import { listAllLearningJourneys } from '../api/learning-journey';
import { DEFAULT_PAGE_SIZE } from '../constants/learning-journey';
import { QUERY_KEYS, QUERY_STALE_TIME_MS } from '../helpers/tanstack-query';
import { ListAllLearningJourneysResponse } from '../types/api';

type useFetchLearningJourneyQueryProps = {
  tab: string;
};

const useListAllLearningJourneys = ({ tab }: useFetchLearningJourneyQueryProps) => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }

  const [response, setResponse] = useState<ListAllLearningJourneysResponse>({
    data: [],
    total: 0,
  });

  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: QUERY_KEYS.listAllLearningJourneys({
        username: auth.user.username,
      }),
      queryFn: ({ pageParam = 1 }) => {
        return listAllLearningJourneys({
          pageSize: DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL,
          pageOffset: (pageParam - 1) * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL,
          tab,
        });
      },
      getNextPageParam: (lastPage, allPages) => {
        const fetchedJourneysCount = allPages.length * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL;
        const totalJourneysCount = lastPage.total;

        if (fetchedJourneysCount >= totalJourneysCount) {
          return undefined;
        }

        const nextPageIndex = allPages.length + 1;
        return nextPageIndex;
      },
      staleTime: QUERY_STALE_TIME_MS.listAllLearningJourneys,
    });

  useEffect(() => {
    if (data) {
      // Combine all pages data into a single response
      const combinedData = data.pages.reduce(
        (acc, page) => ({
          data: [...acc.data, ...page.data],
          total: page.total,
        }),
        { data: [], total: 0 }
      );

      setResponse(combinedData);
    }
  }, [data]);

  return {
    isLoading,
    error,
    data: response,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  };
};

export default useListAllLearningJourneys;
