import { gql } from '@apollo/client';

export const LearningJourneyFragment = gql`
  fragment LearningJourneyFragment on LearningJourneyEntityResponse {
    data {
      attributes {
        ListAllLJs {
          LJStatus {
            statusPlaceHolder
            assignedLabel
            inProgressLabel
            completedLabel
            overDueLabel
          }
          LJNavigator {
            allLJs
          }
          pageHeading
          pageDescription
          allLearningJourneysFetched
          loadingMoreLearningJourneys
          errorLoadingLearningJourneys
          noJourneysActionLabel
          noJourneysMessage
          viewAllLJsButton
        }
        LearningJourneyCard {
          thumbnailAlt
          coursesCountLabel
          linkAriaLabel
          inProgressBadgeLabel
          notStartedBadgeLabel
          completedBadgeLabel
        }
        LearningJourneyDetail {
          headerBackLabel
          allCoursesFetched
          loadingMoreCourses
          errorLoadingCourses
          detailsLoadingErrorMessage
          noCoursesForJourneyMessage
          checkoutOtherJourneysMessage
          learningJourneyAssignmentLabel
        }
        LearningJourneyModal {
          title
          teamLabel
          teamPlaceHolder
          memberLabel
          memberPlaceHolder
          dueDateLabel
          dueDatePlaceHolder
          fixDueDateMessage
          cancelButton
          assignLearningJourneyButton
          orLabel
        }
        ToastMessages {
          learningJourneySuccessTitle
          learningJourneyFailureTitle
          learningJourneyUserAssignedSuccessMessage
          learningJourneyTeamAssignedSuccessMessage
          learningJourneyUserAssignedFailedMessage
          learningJourneyTeamAssignedFailedMessage
        }
      }
    }
  }
`;
