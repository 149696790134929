import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { PATHS } from '../constants/api';
import type {
  GetLearningJourneyDetail,
  ListAllLearningJourneyCoursesResponse,
  ListAllLearningJourneysResponse,
} from '../types/api';

type ListAllLearningJourneysParams = {
  pageSize: number;
  pageOffset: number;
  tab: string;
};

const listAllLearningJourneys = async (
  params: ListAllLearningJourneysParams
): Promise<ListAllLearningJourneysResponse> => {
  const { pageSize, pageOffset, tab } = params;

  const path = PATHS.LEARNING_JOURNEY.LIST;
  const queryParams = { size: pageSize, start: pageOffset, type: tab.toUpperCase() };
  const query = qs.stringify(queryParams);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    withCredentials: true,
  });

  return response;
};

type GetLearningJourneyDetailParams = {
  journeyId: string;
};

const getLearningJourneyDetail = async (
  params: GetLearningJourneyDetailParams
): Promise<GetLearningJourneyDetail> => {
  const { journeyId } = params;

  const path = PATHS.LEARNING_JOURNEY.DETAIL({ id: journeyId });

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });

  return response;
};

type ListAllLearningJourneyCoursesParams = {
  journeyId: string;
  pageSize: number;
  pageOffset: number;
};

const listAllLearningJourneyCourses = async (
  params: ListAllLearningJourneyCoursesParams
): Promise<ListAllLearningJourneyCoursesResponse> => {
  const { pageSize, pageOffset, journeyId } = params;

  const path = PATHS.LEARNING_JOURNEY.COURSES({ id: journeyId });
  const queryParams = { size: pageSize, offset: pageOffset };
  const query = qs.stringify(queryParams);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    withCredentials: true,
  });

  return response;
};

const getLearningJourneyMetaData = (journeyId: string, courseId: string) => {
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    PATHS.LEARNING_JOURNEY.META_DATA({ journeyId, courseId }),
    { withCredentials: true }
  );
};

const getLearningJourneyTeams = (
  courseId: number,
  offset: number,
  limit: number,
  status: string
) => {
  const queryStrings = qs.stringify({ courseId, offset, limit, status });
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${PATHS.LEARNING_JOURNEY.GET_TEAMS}?${queryStrings}`,
    {
      withCredentials: true,
    }
  );
};

const getLearningJourneyUsers = (
  courseId: number,
  offset: number,
  limit: number,
  status: string
) => {
  const queryStrings = qs.stringify({ courseId, offset, limit, status });
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${PATHS.LEARNING_JOURNEY.GET_USERS}?${queryStrings}`,
    {
      withCredentials: true,
    }
  );
};

const userAssignLearningJourney = (payload: {
  username: string[];
  dueDate?: string;
  id: number;
}) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${PATHS.LEARNING_JOURNEY.GET_USERS}`, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

const teamAssignLearningJourney = (payload: {
  teamId: string;
  dueDate?: string;
  isRelative?: boolean;
  id: number;
}) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${PATHS.LEARNING_JOURNEY.GET_TEAMS}`, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export {
  userAssignLearningJourney,
  teamAssignLearningJourney,
  getLearningJourneyDetail,
  listAllLearningJourneyCourses,
  listAllLearningJourneys,
  getLearningJourneyMetaData,
  getLearningJourneyTeams,
  getLearningJourneyUsers,
};
